import React from "react";
import Arrow from "../../src/Images/Right_arrow.svg";

const AssetTablePagination = ({
  currentPage,
  totalPages,
  totalRows,
  rowsPerPage,
  onPageChange,
}) => {
  // Calculate the current range of rows being displayed
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = (currentPage - 1) * rowsPerPage;
  const currentLastRow = Math.min(indexOfLastRow, totalRows);

  return (
    <div className="asset-pagination-container">
      <div className="pagination-info">
        {`${indexOfFirstRow + 1} to ${currentLastRow} of ${totalRows} rows`}
      </div>

      <div className="pagination-controls" style={{ display: "flex", gap: "8px" }}>
        <button
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
          className="asset-pagination-button asset-pagination-button-prev"
        >
          <img src={Arrow} />
        </button>

        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="asset-pagination-button asset-pagination-button-prev"
        >
          <img src={Arrow} />
        </button>

        <span className="asset-pagination-pages">
          Page {currentPage} of {totalPages}
        </span>

        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="asset-pagination-button"
        >
          <img src={Arrow} />
        </button>

        <button
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
          className="asset-pagination-button"
        >
          <img src={Arrow} />
        </button>
      </div>
    </div>
  );
};

export default AssetTablePagination;
