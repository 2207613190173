import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { LocalApiBaseUrl, dataAutomationSubMenuOrder, displayApprovalFeatureData } from "../Shared/Constant";
import i18n from "i18next";
import { Navigation, IOJNavigation } from "./Constant";
import { setUserFeatures } from "./Common"
import { connect } from "react-redux";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


var SynOpsHomeURl = "https://in.accenture.com/accentureoperations/synops/";
class SideBar extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      isAdmin: false,
      viewmore: false,
      data: [],
      roleData: [],
      featureData: [],
      ClientRegions: [],
      MaturityPhase: [],
      viewDealConfig: false,
      addEditCaseStudyLinkStatus: false,
      caseStudypopupStatus: "",
      caseStudylinkData: {},
      OrgEntityID: 0,
      configuratorEditAccess: false,
      addHoverClass: false,
      sideBar: "",
      dataAutomationScreen: ""
    };
    this.onClose = this.onClose.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.getUserData = this.getUserData.bind(this);
  }

  addEditCaseStudyLinkModalShow = (status) => {
    this.setState({
      caseStudypopupStatus: status,
      addEditCaseStudyLinkStatus: true,
    });
  };
  closeCaseStudy = () => {
    this.setState({
      caseStudypopupStatus: "",
      addEditCaseStudyLinkStatus: false,
    });
  };
  saveCaseStudy = (data) => {
    axiosInstance.post(`${LocalApiBaseUrl}CaseStudy/AddEditClientCaseStudy`, data).then((response) => {
      this.setState(
        {
          caseStudylinkData: data,
          caseStudypopupStatus: "",
          addEditCaseStudyLinkStatus: false,
        },
        () => {
          this.getCaseStudyLink();
        }
      );
      alert(response.data.StatusMessage);
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  };
  componentWillUnmount() {
    this._isMounted = false;
    this.getUserData();
  }
  dealpopup(e, type) {
    e.preventDefault();
    if (type === "configurator") {
      this.setState({ viewDealConfig: true });
    } else {
      this.setState({ viewmore: true });
    }
  }
  onClose(e) {
    this.setState({ viewmore: false, viewDealConfig: false });
  }

  async getRoleDataWithClientID(displayName) {
    this.setState({
      dataAutomationScreen: displayName
    })
    let clientId = store.getState().CurrentFilter.ClientData.id;
    await setUserFeatures(clientId, undefined, this.onDataAutomationClickCallback);
  }

  onDataAutomationClickCallback = (data) => {
    const { dataAutomationScreen } = this.state
    const { history } = this.props;
    if (!data.filter(a => a.FeatureName === "Configure Source Application" && a.Submenu === dataAutomationScreen).length > 0) {

      store.dispatch({
        type: 'SETUSERDENIEDSCREEN',

        payload: dataAutomationScreen,
      });
    }
    else {
      store.dispatch({
        type: 'SETUSERDENIEDSCREEN',

        payload: "",
      });
    }
    let pathname = "/admin/Connector/" + dataAutomationScreen.replaceAll(" ", "_").replaceAll("_-_", '_')
    history.push({
      pathname,
      state: { ...history.location.state, detail: dataAutomationScreen },
    });
  }

  getCaseStudyLink = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}CaseStudy/GetClientCaseStudy`, {
        params: {
          OrgEntityID: this.state.OrgEntityID,
        },
      })
      .then((response) => {
        let responseData = response.data;
        this.setState({
          caseStudylinkData: responseData,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };
  deleteCaseStudyLink = () => {
    this.closeCaseStudy();
    this.getCaseStudyLink();
  };
  GetAllMaturityPhase() {
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetMaturityPhase`, null, {
        params: {},
      })
      .then((response) => {
        this.setState({ MaturityPhase: response.data });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  GetAllClientRegion() {
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetAllClientRegion`, null, {
        params: { LanguageId: store.getState().CurrentFilter.languageData.id },
      })
      .then((response) => {
        this.setState({ ClientRegions: response.data });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  componentDidMount() {
    this._isMounted = true;
    this.GetAllClientRegion();
    this.GetAllMaturityPhase();
    this.getUserData();
    //CALLING GET USER FEATURES WITHOUT CLIENT ID AND OFFERING ID IN ADMINSTRATION TAB
    if (this.props.history.location.pathname.startsWith("/admin/Home")) {
      setUserFeatures()
    }

    store.subscribe(() => {
      if (this._isMounted === true) {
        let data = store.getState();
        let confEditAccess =
          data.roleReducers["roleReducers"].filter(
            (data) => data.FeatureName == "Configurator" && data.Operations.includes("Submit")
          ).length > 0
            ? true
            : false;
        this.setState({ roleData: data.roleReducers["roleReducers"], configuratorEditAccess: confEditAccess });
        let featureDetails1 = data.roleReducers["roleReducers"];

        let masterMenu = [];

        var featureNames = featureDetails1.map((ele) => {
          if (ele.Operations != null && ele.Operations.count != 0) {
            masterMenu.push(ele.FeatureName);
          }
        });
        let uniqueMenu = [];
        if (masterMenu != null) {
          uniqueMenu = masterMenu.filter(function (item, pos) {
            return masterMenu.indexOf(item) == pos;
          });
        }

        var filtered = uniqueMenu.filter(function (el) {
          return el != null;
        });
        let featureData = featureDetails1.map(function (el) {
          return el.FeatureName;
        });
        this.setState({ featureData: filtered });

        //Create State Varaibles and use to provide rights
      }
    });
    let orgEntityid =
      store.getState().CurrentFilter.ClientData != undefined ? store.getState().CurrentFilter.ClientData.id : 0;
    this.setState(
      {
        OrgEntityID: orgEntityid,
      },
      () => {
        if (orgEntityid > 0) {
          this.getCaseStudyLink();
        }
      }
    );
  }
  getUserData(){
    this.setState({ isApiLoading: true });
    axiosInstance
      .get(`${LocalApiBaseUrl}User/GetUserDetails`)
      .then((res) => {
        this.setState({ isApiLoading: false });
        if (res.data) {
          this.setState({ 
            isAdmin: res.data[0] === "True"
           });
        }
      })
      .catch((error) => {
        this.setState({ isApiLoading: false });
        trycatchAlertPopup(error);
      });
  }
  navigateFunc = (url) => {
    if (this.props.location.pathname !== url) {
      store.dispatch({
        type: "ADD_SELECTED_TAB_DETAILS",
        payload: {
          title: "",
          description: "",
          component: "",
          ioj: false,
        },
      });
    }
    if (url === "/admin/Configurator" || url === "/Dashboard") {
      this.props.clearFilter();
    }
  };
  onNavigationTabClick = (value) => {
    const { onTabChange } = this.props;

    onTabChange(value);
  };
  addHoverClass = (val) => {
    const { sideBar } = this.state;
    this.setState({ addHoverClass: val, sideBar: val ? sideBar : "" }, () => {
    });
  };

  redirectFrom = (pageIndex) => {
    let lastUrl = "";
    switch (pageIndex) {
      case 1:
        lastUrl = "/admin/Home";
        break;
      case 2:
        lastUrl = "/admin/Configurator";
        break;
    }

    store.dispatch({
      type: "ADMIN_REDIRECT_FROM",
      payload: lastUrl,
    });
  };

  showSidebarFor = (val) => {
    this.setState(
      { sideBar: val },
      () => {
        this.addHoverClass(true);
      },
      () => {
       
      }
    );
  };

  handelHeaderRetain = () => {
    const { history } = this.props;
    const location = {
      state: { showConfiguratorFilterForAdminView: true },
    };
    history.push(location);
  };

  componentDidUpdate(nextProps) {
    if (JSON.stringify(this.props.history) !== JSON.stringify(nextProps.history)) {
      this.setState({ addHoverClass: false, sideBar: "" });
      if (this.props.history.location.pathname.startsWith("/admin/Home")) {
        setUserFeatures()
      }
    }
  }

  render() {
    const {
      caseStudylinkData,
      addEditCaseStudyLinkStatus,
      caseStudypopupStatus,
      OrgEntityID,
      configuratorEditAccess,
      addHoverClass,
      sideBar,
    } = this.state;
    const exceptAdminFeatureData = ["DEI"];
    const exceptApprovalFeatureData = ["Client Approval", "Location Approval"];

    const adminSectionFrom = store.getState().CurrentFilter.adminFrom;
    const {
      history,
      tabData,
      roleReducerData,
      SelectedFilter,
      masterFilterValues,
      ClientData,
      SelectedClient,
    } = this.props;
    const configView =
      history.location.state && history.location.state.showConfiguratorFilterForAdminView ? true : false;

    let dataAutomationSubMenu = [];
    let masterSubMenu = [];
    let approvalSubMenu = [];
    if (roleReducerData) {
      //Data Automation
      dataAutomationSubMenuOrder
        .sort((a, b) => (a.orderSeq > b.orderSeq ? 1 : -1))
        .forEach((fe) => {
          const subData = roleReducerData.filter((f) => {
            return f.FeatureName === "Configure Source Application" && f.Submenu === fe.name;
          });
          if (subData.length)
            dataAutomationSubMenu.push({
              Submenu: subData[0].Submenu,
              DisplayName: fe.displayName == "" ? subData[0].Submenu : fe.displayName,
            });
        });

      //Master
      roleReducerData
        .filter((f) => {
          return f.FeatureName === "Master";
        })
        .forEach((fe) => {
          if (exceptAdminFeatureData.findIndex((i) => i == fe.Submenu) == -1)
            masterSubMenu.push({ Submenu: fe.Submenu, DisplayName: fe.Submenu });
        });
      masterSubMenu.push({ Submenu: "Notification", DisplayName: "Notifications" });
      masterSubMenu.push({ Submenu: "Message Board", DisplayName: "" });
      if(this.state.isAdmin){
        masterSubMenu.push({ Submenu: "Url WhilteList", DisplayName: "" });
      }

      //Approval
      roleReducerData
        .filter((f) => {
          return f.FeatureName === "Approval";
        })
        .forEach((fe) => {
          if (exceptApprovalFeatureData.findIndex((i) => i == fe.Submenu) == -1) {
            const displayNm = displayApprovalFeatureData.filter((i) => {
              return i.name == fe.Submenu;
            });
            approvalSubMenu.push({
              Submenu: fe.Submenu,
              DisplayName: displayNm.length > 0 ? displayNm[0].displayName : fe.Submenu,
            });
          }
        });
    }

    const masterAdminHeader =
      (history.location.pathname.startsWith("/admin/Home") ||
        history.location.pathname.startsWith("/admin/Master") ||
        history.location.pathname.startsWith("/admin/MasterTemplateConfigurator") ||
        history.location.pathname.startsWith("/admin/Connector") ||
        history.location.pathname.startsWith("/admin/UserTrends") ||
        history.location.pathname.startsWith("/admin/Approval") ||
        history.location.pathname.startsWith("/admin/Report") ||
        history.location.pathname.startsWith("/admin/GOCForm")) &&
        !configView
        ? true
        : false;

    //Const for Sidebar Display
    const isDisplayAdmin =
      (this.state.featureData.includes("Reports") ||
        this.state.featureData.includes("Approval") ||
        this.state.featureData.includes("User Trends") ||
        this.state.featureData.includes("Master") ||
        this.state.featureData.includes("Connector") ||
        this.state.featureData.includes("Master Template Configurator")) &&
      masterAdminHeader &&
      (adminSectionFrom === "" || adminSectionFrom === "/admin/Home");
    const isDisplayFD = !masterAdminHeader || (adminSectionFrom == "/admin/Configurator" || configView);
    const isDisplayConfig =
      (!masterAdminHeader && history.location.pathname === "/admin/Configurator") ||
      (adminSectionFrom == "/admin/Configurator" || configView);
    const diasableSideBarButton =
      SelectedFilter &&
        SelectedFilter.LocationId &&
        SelectedFilter.OfferingId &&
        SelectedFilter.ProcessId &&
        (tabData.title !== "Journey Initiatives"
          ? SelectedFilter.OfferingId !== "All" ||
            SelectedFilter.ProcessId !== "All" ||
            SelectedFilter.LocationId !== "All"
            ? true
            : false
          : true) &&
        (SelectedFilter.Month || (tabData.title === "Journey Initiatives" && SelectedFilter.LocationOMID.length))
        ? false
        : true;
    const noSidebarPages =
      history.location.pathname === "/OperationalReviews" ||
      history.location.pathname === "/bat" ||
      history.location.pathname.startsWith("/asset-adoption-inventory");
    const showNotificationMenu = ClientData && ClientData.length && ClientData[0].IsAdmin ? true : false;
    return (
      <>
        {addHoverClass ? (
          <div
            className="sidebarmenu-open-overlay"
            onClick={() => {
              this.addHoverClass(false);
            }}
          ></div>
        ) : null}
        {!noSidebarPages && (isDisplayAdmin || isDisplayFD || isDisplayConfig) && (
          <div className={`main-sidebar newsidebar-menu ${addHoverClass ? "sidebar-open" : ""}`}>
            <ul className="parent-leftmenu">
              {/* Links for Master Section Start */}
              {/*!this.props.ViewAdmin && */}
              {isDisplayAdmin && (
                <li className="sidebarchild-menu">
                  <ul>
                    {this.state.featureData.includes("Master") && (
                      <li className="sidebarmenu-icon dropdown">
                        <Link
                          to="#"
                          onClick={() => {
                            this.showSidebarFor("Master");
                          }}
                          className={`sidebarmenu-sublist dropdown-toggle ${history.location.pathname.startsWith("/admin/Master/") ? "active" : ""
                            }`}
                        // data-toggle="dropdown"
                        >
                          <span className="sidebarmenu-iconnav master-icon"></span>
                          <h5>{i18n.t("Master")}</h5>
                        </Link>
                        {sideBar === "Master" && (
                          <div className={`sidebarmenu-childmenu-block dropdown-menu show`}>
                            <button
                              type="button"
                              onClick={() => {
                                this.addHoverClass(false);
                              }}
                              class="close"
                            >
                              <span aria-hidden="true">×</span>
                              <span class="sr-only">{i18n.t("Close")}</span>
                            </button>
                            <h4>{i18n.t("Master")}</h4>
                            <ul>
                              {masterSubMenu &&
                                masterSubMenu
                                  .sort((a, b) => (a.Submenu > b.Submenu ? 1 : -1))
                                  .map((each) => {
                                    if (
                                      (each.Submenu === "Notification" || each.Submenu === "Message Board") &&
                                      !showNotificationMenu
                                    ) {
                                      return "";
                                    } else {
                                      return (
                                        <li>
                                          <NavLink
                                            to={{
                                              pathname: "/admin/Master/" + each.Submenu.replaceAll(" ", "_"),
                                              state: { detail: each.Submenu },
                                            }}
                                            onClick={() => {
                                              this.addHoverClass(false);
                                            }}
                                          >
                                            {i18n.t(each.Submenu)}
                                          </NavLink>
                                        </li>
                                      );
                                    }
                                  })}
                            </ul>
                          </div>
                        )}
                      </li>
                    )}
                    {this.state.featureData.includes("Master Template Configurator") && (
                      <li className="sidebarmenu-icon dropdown">
                        <Link
                          to={"/admin/MasterTemplateConfigurator"}
                          className={`sidebarmenu-sublist dropdown-toggle ${history.location.pathname.startsWith("/admin/MasterTemplateConfigurator") ? "active" : ""
                            }`}
                          // data-toggle="dropdown"
                          onClick={(e) => {
                            this.showSidebarFor("MTC");
                          }}
                        >
                          <span className="sidebarmenu-iconnav mtc-icon"></span>
                          <h5>{i18n.t("Master_Template_Configurator")}</h5>
                        </Link>

                        {sideBar === "MTC" && masterFilterValues && masterFilterValues.offering && masterFilterValues.process && (
                          <div className={`sidebarmenu-childmenu-block dropdown-menu show`}>
                            <button
                              type="button"
                              class="close"
                              onClick={() => {
                                this.addHoverClass(false);
                              }}
                            >
                              <span aria-hidden="true">×</span>
                              <span class="sr-only">{i18n.t("Close")}</span>
                            </button>
                            <h4>{i18n.t("Master_Template_Configurator")}</h4>

                            <ul>
                              {Navigation.map((name, index) => {
                                return (
                                  <li
                                    disabled={
                                      masterFilterValues &&
                                        (!masterFilterValues.offering || !masterFilterValues.process)
                                        ? true
                                        : !masterFilterValues
                                          ? true
                                          : false
                                    }
                                  >
                                    {/* className="sidebarmenu-icon"> */}
                                    <Link
                                      to={"/admin/MasterTemplateConfigurator"}
                                      className={
                                        masterFilterValues &&
                                          (!masterFilterValues.offering || !masterFilterValues.process)
                                          ? "disabled"
                                          : !masterFilterValues
                                            ? "disabled"
                                            : tabData.title === name.itemName
                                              ? "active"
                                              : ""
                                      }
                                      onClick={() => {
                                        this.onNavigationTabClick(name.itemName);
                                        this.addHoverClass(false);
                                      }}
                                      disabled={
                                        masterFilterValues &&
                                          (!masterFilterValues.offering || !masterFilterValues.process)
                                          ? name.itemName === "Business Outcomes"
                                            ? false
                                            : true
                                          : true
                                      }
                                    >
                                      {/* <span className={`sidebarmenu-iconnav ${name.image}`}></span> */}
                                      {name.itemValue}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>

                          </div>
                        )}
                      </li>
                    )}
                    {this.state.featureData.includes("Configure Source Application") && (
                      <li className="sidebarmenu-icon">
                        <NavLink
                          to="#"
                          className={`sidebarmenu-sublist dropdown-toggle ${history.location.pathname.startsWith("/admin/Connector/") ? "active" : ""
                            }`}
                          // data-toggle="dropdown"
                          onClick={() => {
                            this.showSidebarFor("DA");
                          }}
                        >
                          <span className="sidebarmenu-iconnav csa-icon"></span>
                          <h5>{i18n.t("Data Automation")}</h5>
                        </NavLink>
                        {sideBar === "DA" && (
                          <div className={`sidebarmenu-childmenu-block dropdown-menu show`}>
                            <button
                              type="button"
                              onClick={() => {
                                this.addHoverClass(false);
                              }}
                              class="close"
                            >
                              <span aria-hidden="true">×</span>
                              <span class="sr-only">{i18n.t("Close")}</span>
                            </button>
                            <h4>{i18n.t("Data Automation")}</h4>
                            <ul>
                              {dataAutomationSubMenu &&
                                dataAutomationSubMenu.map((each) => {
                                  return (
                                    <li>
                                      <NavLink
                                        to={{
                                          pathname: "/admin/Connector/" + each.Submenu.replaceAll(" ", "_"),
                                          state: { detail: each.Submenu },
                                        }}
                                        onClick={() => {
                                          this.redirectFrom(1);
                                          this.addHoverClass(false);
                                          this.getRoleDataWithClientID(each.Submenu)
                                        }}
                                      >
                                        {i18n.t(each.DisplayName)}
                                      </NavLink>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        )}
                      </li>
                    )}
                    {this.state.featureData.includes("Approval") && (
                      <li className="sidebarmenu-icon">
                        <NavLink
                          to="#"
                          className={`sidebarmenu-sublist dropdown-toggle ${history.location.pathname.startsWith("/admin/Approval/") ? "active" : ""
                            }`}
                          // data-toggle="dropdown"
                          onClick={() => {
                            this.showSidebarFor("Approval");
                          }}
                        >
                          <span className="sidebarmenu-iconnav approval-icon"></span>
                          <h5>{i18n.t("Approval")}</h5>
                        </NavLink>
                        {sideBar === "Approval" && (
                          <div className={`sidebarmenu-childmenu-block dropdown-menu show`}>
                            <button
                              type="button"
                              class="close"
                              onClick={() => {
                                this.addHoverClass(false);
                              }}
                            >
                              <span aria-hidden="true">×</span>
                              <span class="sr-only">{i18n.t("Close")}</span>
                            </button>
                            <h4>{i18n.t("Approval")}</h4>
                            <ul>
                              {approvalSubMenu &&
                                approvalSubMenu
                                  .sort((a, b) => (a.DisplayName > b.DisplayName ? 1 : -1))
                                  .map((each) => {
                                    return (
                                      <li>
                                        <NavLink
                                          to={{
                                            pathname: "/admin/Approval/" + each.Submenu.replaceAll(" ", "_"),
                                            state: {
                                              detail: each.Submenu,
                                            },
                                          }}
                                          onClick={() => {
                                            this.redirectFrom(1);
                                            this.addHoverClass(false);
                                          }}
                                        >
                                          <>{i18n.t(each.DisplayName)}</>
                                        </NavLink>
                                      </li>
                                    );
                                  })}
                            </ul>
                          </div>
                        )}
                      </li>
                    )}
                    {this.state.featureData.includes("Reports") && (
                      <li className="sidebarmenu-icon">
                        <NavLink
                          to="#"
                          className={`sidebarmenu-sublist dropdown-toggle ${history.location.pathname.startsWith("/admin/Report/") ? "active" : ""
                            }`}
                          // data-toggle="dropdown"
                          onClick={() => {
                            this.showSidebarFor("Reports");
                          }}
                        >
                          <span className="sidebarmenu-iconnav reports-icon"></span>
                          <h5>{i18n.t("Reports")}</h5>
                        </NavLink>
                        {sideBar === "Reports" && (
                          <div className={`sidebarmenu-childmenu-block dropdown-menu show`}>
                            <button
                              type="button"
                              onClick={() => {
                                this.addHoverClass(false);
                              }}
                              class="close"
                            >
                              <span aria-hidden="true">×</span>
                              <span class="sr-only">{i18n.t("Close")}</span>
                            </button>
                            <h4>{i18n.t("Reports")}</h4>
                            <ul>
                              {roleReducerData &&
                                roleReducerData
                                  .filter((f) => {
                                    return f.FeatureName === "Reports";
                                  })
                                  .sort((a, b) => (a.Submenu > b.Submenu ? 1 : -1))
                                  .map((each) => {
                                    return (
                                      <li>
                                        <NavLink
                                          to={{
                                            pathname: "/admin/Report/" + each.Submenu.replaceAll(" ", "_"),
                                            state: { detail: each.Submenu },
                                          }}
                                          onClick={() => {
                                            this.redirectFrom(1);
                                            this.addHoverClass(false);
                                          }}
                                        >
                                          {i18n.t(each.Submenu)}
                                        </NavLink>
                                      </li>
                                    );
                                  })}
                            </ul>
                          </div>
                        )}
                      </li>
                    )}
                    {this.state.featureData.includes("User Trends") && (
                      <li className="sidebarmenu-icon">
                        <NavLink
                          to="#"
                          className={`sidebarmenu-sublist dropdown-toggle ${history.location.pathname.startsWith("/admin/UserTrends/") ? "active" : ""
                            }`}
                          // data-toggle="dropdown"
                          onClick={() => {
                            this.showSidebarFor("UT");
                          }}
                        >
                          <span className="sidebarmenu-iconnav ii-icon"></span>
                          <h5>{i18n.t("User_Trends")}</h5>
                        </NavLink>
                        {sideBar === "UT" && (
                          <div className={`sidebarmenu-childmenu-block dropdown-menu show`}>
                            <button
                              type="button"
                              class="close"
                              onClick={() => {
                                this.addHoverClass(false);
                              }}
                            >
                              <span aria-hidden="true">×</span>
                              <span class="sr-only">{i18n.t("Close")}</span>
                            </button>
                            <h4>{i18n.t("User_Trends")}</h4>
                            <ul>
                              {roleReducerData &&
                                roleReducerData
                                  .filter((f) => {
                                    return f.FeatureName === "User Trends";
                                  })
                                  .sort((a, b) => (a.Submenu > b.Submenu ? 1 : -1))
                                  .map((each) => {
                                    return (
                                      <li>
                                        <NavLink
                                          to={{
                                            pathname: "/admin/UserTrends/" + each.Submenu.replaceAll(" ", "_"),
                                            state: { detail: each.Submenu },
                                          }}
                                          onClick={() => {
                                            this.addHoverClass(false);
                                          }}
                                        >
                                          {i18n.t(each.Submenu)}
                                        </NavLink>
                                      </li>
                                    );
                                  })}
                            </ul>
                          </div>
                        )}
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {/* Links for Master Section End */}
              {/* Links for FD Section Start */}

              {isDisplayFD && (
                <li className="sidebarchild-menu">
                  <h4>{i18n.t("SynOps_Delivered")}</h4>
                  <ul>
                    {Navigation.map((name, index) => {
                      if (name.itemName !== "Tools") {
                        return (
                          <li className={`sidebarmenu-icon ${diasableSideBarButton ? "disabled" : ""}`}>
                            <Link
                              to={configView ? "/admin/Configurator" : history.location.pathname}
                              className={
                                tabData.title === name.itemName &&
                                  !diasableSideBarButton &&
                                  (history.location.pathname === "/admin/Configurator" ||
                                    history.location.pathname === "/Dashboard")
                                  ? "active"
                                  : ""
                              }
                              onClick={() => {
                                this.onNavigationTabClick(name.itemName);
                                this.addHoverClass(false);
                              }}
                            >
                              <span className={`sidebarmenu-iconnav ${name.image}`}></span>
                              <h5>{name.itemValue}</h5>
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </li>
              )}
              {isDisplayFD && (
                <li className="sidebarchild-menu">
                  <h4>{i18n.t("Intelligent_Operations_Journey")}</h4>
                  <ul>
                    {IOJNavigation.map((name, index) => {
                      if (
                        (window.location.pathname === "/admin/Configurator" &&
                          name.itemName === "Initiative Comparison") ||
                        (window.location.pathname === "/initiativedetails" && name.itemName === "Initiative Comparison")
                      ) {
                      }
                      else if(

                        (window.location.pathname === "/admin/Configurator" &&

                        name.itemName === "Value Realization")



                      ){}
                      else if(

                        (window.location.pathname === "/admin/Configurator" &&

                        name.itemName === "IOJ Scorecard")



                      ){}
                      else {
                        return (
                          (name.itemName !== "IOJ Scorecard" || roleReducerData.map((i) => i.FeatureName).includes("IOJ Scorecard")) &&
                          <li
                            className={`sidebarmenu-icon  ${addHoverClass ? "showSidebar1" : ""} ${diasableSideBarButton ? "disabled" : ""
                              }`}
                          >
                            <Link
                              to={configView ? "/admin/Configurator" : history.location.pathname}
                              className={
                                tabData.title === name.itemName &&
                                  !diasableSideBarButton &&
                                  (history.location.pathname === "/admin/Configurator" ||
                                    history.location.pathname === "/Dashboard")
                                  ? "active"
                                  : ""
                              }
                              onClick={() => {
                                this.onNavigationTabClick(name.itemName);
                                this.addHoverClass(false);
                              }}
                              disabled={diasableSideBarButton}
                            >
                              <div className= {name.itemName==="IOJ Scorecard"?"parentcontainer":""}>
                              <span className={`sidebarmenu-iconnav ${name.image}`}></span>
                              <h5>{name.itemValue}</h5>
                              </div>
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </li>
              )}
              {isDisplayConfig && (
                this.state.featureData.includes("Configure Source Application") ||
                this.state.featureData.includes("Reports") ||
                this.state.featureData.includes("Approval")
              ) && (
                  <li className="sidebarchild-menu">
                    <h4>{i18n.t("Links")}</h4>
                    <ul>
                      {this.state.featureData.includes("Configure Source Application") && (
                        <li className={`sidebarmenu-icon ${!SelectedClient.id ? "disabled" : ""}`}>
                          <Link
                            to={{
                              pathname: history.location.pathname,
                              state: {
                                detail: history.location.state ? history.location.state.detail : "",
                                showConfiguratorFilterForAdminView: true,
                              },
                            }}
                            className={`sidebarmenu-sublist dropdown-toggle ${history.location.pathname.startsWith("/admin/Connector/") ? "active" : ""
                              }`}
                            onClick={() => {
                              this.handelHeaderRetain();
                              this.showSidebarFor("DAC");
                            }}

                          //data-toggle="dropdown"
                          >
                            <span className="sidebarmenu-iconnav csa-icon"></span>
                            <h5>{i18n.t("Data Automation")}</h5>
                          </Link>
                          {sideBar === "DAC" && (
                            <div className={`sidebarmenu-childmenu-block dropdown-menu show`}>
                              <button
                                type="button"
                                onClick={() => {
                                  this.addHoverClass(false);
                                }}
                                class="close"
                              >
                                <span aria-hidden="true">×</span>
                                <span class="sr-only">{i18n.t("Close")}</span>
                              </button>
                              <h4>{i18n.t("Data Automation")}</h4>
                              <ul>
                                {dataAutomationSubMenu &&
                                  dataAutomationSubMenu.map((each) => {
                                    return (
                                      <li>
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            this.redirectFrom(2);
                                            this.addHoverClass(false);
                                            this.getRoleDataWithClientID(each.Submenu)
                                          }}
                                        >
                                          {i18n.t(each.DisplayName)}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          )}
                        </li>
                      )}
                      {this.state.featureData.includes("Reports") ? (
                        <li className={`sidebarmenu-icon ${!SelectedClient.id ? "disabled" : ""}`}>
                          <Link
                            to={{
                              pathname: history.location.pathname,
                              state: {
                                detail: history.location.state ? history.location.state.detail : "",
                                showConfiguratorFilterForAdminView: true,
                              },
                            }}
                            className={`sidebarmenu-sublist dropdown-toggle ${history.location.pathname.startsWith("/admin/Report/") ? "active" : ""
                              }`}
                            // data-toggle="dropdown"

                            onClick={() => {
                              this.handelHeaderRetain();
                              this.showSidebarFor("ReportsC");
                            }}
                          >
                            <span className="sidebarmenu-iconnav reports-icon"></span>
                            <h5>{i18n.t("Reports")}</h5>
                          </Link>
                          {sideBar === "ReportsC" && (
                            <div className={`sidebarmenu-childmenu-block dropdown-menu show`}>
                              <button
                                type="button"
                                class="close"
                                onClick={() => {
                                  this.addHoverClass(false);
                                }}
                              >
                                <span aria-hidden="true">×</span>
                                <span class="sr-only">{i18n.t("Close")}</span>
                              </button>
                              <h4>{i18n.t("Reports")}</h4>
                              <ul>
                                {roleReducerData &&
                                  roleReducerData
                                    .filter((f) => {
                                      return f.FeatureName === "Reports";
                                    })
                                    .sort((a, b) => (a.Submenu > b.Submenu ? 1 : -1))
                                    .map((each) => {
                                      return (
                                        <li>
                                          <NavLink
                                            to={{
                                              pathname: "/admin/Report/" + each.Submenu.replaceAll(" ", "_"),
                                              state: { detail: each.Submenu, showConfiguratorFilterForAdminView: true },
                                            }}
                                            onClick={() => {
                                              this.redirectFrom(2);
                                              this.addHoverClass(false);
                                            }}
                                          >
                                            {i18n.t(each.Submenu)}
                                          </NavLink>
                                        </li>
                                      );
                                    })}
                              </ul>
                            </div>
                          )}
                        </li>
                      ) : null}
                      {this.state.featureData.includes("Approval") ? (
                        <li className={`sidebarmenu-icon ${!SelectedClient.id ? "disabled" : ""}`}>
                          <Link
                            to={{
                              pathname: history.location.pathname,
                              state: {
                                detail: history.location.state ? history.location.state.detail : "",
                                showConfiguratorFilterForAdminView: true,
                              },
                            }}
                            className={`sidebarmenu-sublist dropdown-toggle ${history.location.pathname.startsWith("/admin/Approval/") ? "active" : ""
                              }`}
                            //data-toggle="dropdown"
                            onClick={() => {
                              this.handelHeaderRetain();
                              this.showSidebarFor("ApprovalC");
                            }}
                          >
                            <span className="sidebarmenu-iconnav approval-icon"></span>
                            <h5>{i18n.t("Approval")}</h5>
                          </Link>
                          {sideBar === "ApprovalC" && (
                            <div className={`sidebarmenu-childmenu-block dropdown-menu show`}>
                              <button
                                type="button"
                                class="close"
                                onClick={() => {
                                  this.addHoverClass(false);
                                }}
                              >
                                <span aria-hidden="true">×</span>
                                <span class="sr-only">{i18n.t("Close")}</span>
                              </button>
                              <h4>{i18n.t("Approval")}</h4>
                              <ul>
                                {approvalSubMenu &&
                                  approvalSubMenu
                                    .sort((a, b) => (a.DisplayName > b.DisplayName ? 1 : -1))
                                    .map((each) => {
                                      return (
                                        <li>
                                          <NavLink
                                            to={{
                                              pathname: "/admin/Approval/" + each.Submenu.replaceAll(" ", "_"),
                                              state: { detail: each.Submenu, showConfiguratorFilterForAdminView: true },
                                            }}
                                            onClick={() => {
                                              this.redirectFrom(2);
                                              this.addHoverClass(false);
                                            }}
                                          >
                                            <>{i18n.t(each.DisplayName)}</>
                                          </NavLink>
                                        </li>
                                      );
                                    })}
                              </ul>
                            </div>
                          )}
                        </li>
                      ) : null}
                    </ul>
                  </li>
                )}
              {/* Links for FD Section End */}
            </ul>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    tabData: { ...state.CurrentFilter.Tab },
    languageData: { ...state.CurrentFilter.languageData },
    roleReducerData: state.roleReducers.roleReducers,
    SelectedFilter: { ...state.CurrentFilter.SyncFilter },
    ClientData: state.ClientAndFilterReducer.ClientData,
    SelectedClient: state.CurrentFilter.ClientData,
  };
};
export default connect(mapStateToProps)(SideBar);
