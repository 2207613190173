import React, { useEffect } from "react";
import axiosInstance from "../Shared/interceptor";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function AssetInventoryClientSelection(props) {
  const [clients, setClients] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    axiosInstance.get(`${LocalApiBaseUrl}AssetInventory/GetCorebeatClientContracts`).then((res) => {
      setClients(res.data);
      props.onSaveAccess(res.data.length > 0);
    });
  }, []);



  const selectedContract = props.contract;

  const contracts = clients.find((client) => client.ClientName === props.client)
    ? clients.find((client) => client.ClientName === props.client).ContractNumber
    : [];

  const clearAllDropdowns = () => {
    props.onSaveOMID(null);
    props.onSaveAllOffering(null);
    props.onSaveAllSubOffering(null);
    props.onSaveOfferingData(null);
    props.onSaveMainOffering(null);
    props.onSaveOffering(null);
    props.onSaveMainOffering(null);
    props.onSaveOfferingSSG(null);
    props.onSaveSubOffering(null);
    props.onSaveProcess(null);
    props.onSaveRole(null);
  }

  const handleClientChange = (client) => {
    props.onSaveClient(client);
    props.onSaveContract("Select Contract");
    clearAllDropdowns();
  }

  const handleContractChange = (contract) => {
    props.onSaveContract(contract);
    clearAllDropdowns();
  }

  return (
    <>
      <li className="header-selectclient select-data">
        <div className="asset-inventory-dropdown" onClick={props.isClientDropdown}>
          <span className="client-name">
            {!props.client
              ? "Select Client"
              : props.client}
          </span>
          {props.isClientDropdownStatus && clients.length > 0 && (
            <div className="client-select-list">
              <p className="select-client-text">
                <span>Select Client</span>
              </p>
              <div className="searchFilter">
                <input
                  id="client-select-dropdown"
                  type="search"
                  placeholder="Search ..."
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                />
              </div>
              <ul>
                {clients
                  .filter((c) => c.ClientName.toLowerCase().includes(searchText.toLowerCase()))
                  .map((contract, index) => (
                    <li
                      key={index}
                      onClick={() => handleClientChange(contract.ClientName)}
                      className={
                        props.client === contract.ClientName ? "active" : ""
                      }
                    >
                      <span>{contract.ClientName}</span>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
        {props.isClientDropdownStatus && (
          <div className="client-select-list-overlay" onClick={props.isClientDropdownOverlay} />
        )}
      </li>
      <li className="header-selectclient select-data">
        <div className="asset-inventory-dropdown" onClick={props.isContractDropdown}>
          <span className="client-name">
            {!selectedContract
              ? "Select Contract"
              : selectedContract}
          </span>
          {props.isContractDropdownStatus && contracts.length > 0 && (
            <div className="client-select-list">
              <p className="select-client-text">
                <span>Select Contract</span>
              </p>
              <ul>
                {contracts.map((contract, index) => (
                  <li
                    key={index}
                    onClick={() => handleContractChange(contract)}
                    className={
                      selectedContract === contract ? "active" : ""
                    }
                  >
                    <span>{contract}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {props.isContractDropdownStatus && (
          <div className="client-select-list-overlay" onClick={props.isContractDropdownOverlay} />
        )}
      </li>
    </>
  );
}

const mapStateToProps = (state) => ({
  client: state.assetInventoryReducer.assetInventoryClient,
  contract: state.assetInventoryReducer.assetInventoryContract,
});

const mapDispatchToProps = (dispatch) => ({
  onSaveAccess: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_ACCESS", payload: data });
  },
  onSaveClient: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_CLIENT", payload: data });
  },
  onSaveContract: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_CONTRACT", payload: data });
  },
  onSaveOMID: (data) => {
    dispatch({ type: 'SET_ASSET_INVENTORY_OMIDS', payload: data });
  },
  onSaveAllOffering: (data) => {
    dispatch({ type: 'SET_ASSET_INVENTORY_ALL_OFFERING', payload: data });
  },
  onSaveAllSubOffering: (data) => {
    dispatch({ type: 'SET_ASSET_INVENTORY_ALL_SUB_OFFERING', payload: data });
  },
  onSaveOfferingData: (data) => {
    dispatch({ type: 'SET_ASSET_INVENTORY_OFFERING_DATA', payload: data });
  },
  onSaveMainOffering: (data) => {
    dispatch({ type: 'SET_ASSET_INVENTORY_MAIN_OFFERING', payload: data });
  },
  onSaveOffering: (data) => {
    dispatch({ type: 'SET_ASSET_INVENTORY_OFFERING', payload: data });
  },
  onSaveOfferingSSG: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_OFFERING_SSG", payload: data });
  },
  onSaveSubOffering: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_SUB_OFFERING", payload: data });
  },
  onSaveProcess: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_PROCESS", payload: data });
  },

  onSaveRole: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_ROLE", payload: data });
  },
  onSaveClient: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_CLIENT", payload: data });
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetInventoryClientSelection));
