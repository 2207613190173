import {
  SET_ASSET_INVENTORY_ACCESS,
  SET_ASSET_INVENTORY_CLIENT,
  SET_ASSET_INVENTORY_CONTRACT,
  SET_ASSET_INVENTORY_MAIN_OFFERING,
  SET_ASSET_INVENTORY_OFFERING_SSG,
  SET_ASSET_INVENTORY_SUB_OFFERING,
  SET_ASSET_INVENTORY_PROCESS,
  SET_ASSET_INVENTORY_OMIDS,
  SET_ASSET_INVENTORY_ROLE,
  SET_ASSET_INVENTORY_OFFERING_DATA,
  SET_ASSET_INVENTORY_ALL_OFFERING,
  SET_ASSET_INVENTORY_ALL_SUB_OFFERING
} from "../actions/assetInventoryActions";

const assetInventoryInitialState = {};

const assetInventoryReducer = (state = assetInventoryInitialState, action) => {
  switch (action.type) {
    case SET_ASSET_INVENTORY_ACCESS:
      return {
        ...state,
        assetInventoryAccess: action.payload,
      };
    case SET_ASSET_INVENTORY_CLIENT:
      return {
        ...state,
        assetInventoryClient: action.payload,
      };
    case SET_ASSET_INVENTORY_CONTRACT:
      return {
        ...state,
        assetInventoryContract: action.payload,
      };
    case SET_ASSET_INVENTORY_OMIDS:
      return {
        ...state,
        assetInventoryOmids: action.payload,
      };
    case SET_ASSET_INVENTORY_ROLE:
      return {
        ...state,
        assetInventoryRole: action.payload,
      };
    case SET_ASSET_INVENTORY_MAIN_OFFERING:
      return {
        ...state,
        assetInventoryMainOffering: action.payload,
      };
      case SET_ASSET_INVENTORY_OFFERING_SSG:
        return {
          ...state,
          assetInventoryOfferingSSG: action.payload,
        };
      case SET_ASSET_INVENTORY_SUB_OFFERING:
        return {
          ...state,
          assetInventorySubOffering: action.payload,
        };
    case SET_ASSET_INVENTORY_PROCESS:
      return {
        ...state,
        assetInventoryProcess: action.payload,
      };
    case SET_ASSET_INVENTORY_ALL_OFFERING:
      return {
        ...state,
        assetInventoryAllOffering: action.payload,
      };
    case SET_ASSET_INVENTORY_ALL_SUB_OFFERING:
      return {
        ...state,
        assetInventoryAllSubOffering: action.payload,
      };
    case SET_ASSET_INVENTORY_OFFERING_DATA:
      return {
        ...state,
        assetInventoryOfferingData: action.payload,
      };
    default:
      return state;
  }
};

export default assetInventoryReducer;
