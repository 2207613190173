import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SynopsLoader from "../Shared/SynopsLoader";
import SearchableDropdown from "./SearchableDropdown";
import axiosInstance from "../Shared/interceptor";
import { LocalApiBaseUrl, regexForAssetInputs } from "../Shared/Constant";
import ReactTooltip from "react-tooltip";
import noFilter from "../Images/funnel-icon.svg";
import SaveIcon from "../Images/save-asset-icon.svg";
import CancelIcon from "../Images/cancel-asset-icon.svg";
import DeleteIcon from "../Images/delete-asset-icon.svg";

import "./assetAdoptionInventory.css";
import AssetTablePagination from "./AssetTablePagination";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem(
  "msal.idtoken"
)}`;

const AssetAdoptionContent = (props) => {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [standardAssets, setStandardAssets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [appConfigList, setAppConfigList] = useState([]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentAssets = assets.slice(indexOfFirstRow, indexOfLastRow);

  const synopsClassificationOptions = [
    "SynOps Frontdoor",
    "Human + Machine",
    "Work Orchestration",
    "Diverse Data",
    "Insights + Intelligence",
    "SynOps Experience",
  ];

  const journeyStageOptions = [
    "Not Applicable",
    "Foundation",
    "Automated",
    "Value driven",
    "Intelligent",
  ];

  const assetTypeOptions = ["Standard", "Custom"];
  const statusOptions = ["To be Deployed", "Deployed", "Sunset", "Dropped"];
  const owningEntityOptions = ["Client", "Accenture", "Others"];

  const getUniqueRoles = (roles) => {
    return [...new Map(roles.map((role) => [role.RoleName, role])).values()];
  };

  const hasEditPermission = (roles) => {
    return getUniqueRoles(roles).some((role) => role.CanEdit);
  };

  const getAssetApproverAPI = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${LocalApiBaseUrl}AssetInventory/GetAssetApprover`,
        {
          params: {
            OMIDs: props.omids.join(","),
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const fetchAssets = () => {
    setLoading(true);
    axiosInstance
      .get(`${LocalApiBaseUrl}AssetInventory/GetAssets?OMIDs=${props.omids}`)
      .then((response) => {
        const formattedAssets = response.data
          .filter((asset) => asset.IsActive)
          .map((asset) => ({
            id: asset.AssetId,
            approvalStatus: asset.AssetType === "Standard" ? "N/A" : asset.ApprovalStatus,
            assetType: asset.AssetType,
            assetName: asset.AssetName,
            description: asset.AssetDescription,
            owningEntity: asset.AssetOwningEntity,
            status: asset.AssetStatus,
            synopsClassification: asset.SynopsClassification,
            journeyStage: asset.JourneyStage,
            goLiveDate: asset.GoLiveDate ? new Date(asset.GoLiveDate) : null,
            sunsetDate: asset.SunsetDate ? new Date(asset.SunsetDate) : null,
            isEditing: false,
          }));
        setAssets(formattedAssets);
      })
      .catch((error) => {
        console.error("Error fetching assets:", error);
        alert("Failed to load assets. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAppConfig = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetAppConfig`)
      .then((response) => {
          setAppConfigList(response.data)
      })
      .catch((error) => {
        console.error("Error fetching app config:", error);
      });
  }


  useEffect(() => {
    if (props.omids) {
      fetchAssets();
    }
  }, [props.omids]);

  useEffect(() => {
    setTotalPages(Math.ceil(assets.length / rowsPerPage));
  }, [assets, rowsPerPage]);

  useEffect(() => {
    getAppConfig();
  }, []);

  const assetEmailTriggerConfig = appConfigList.find(
    (config) => config.ConfigKey === "AssetEmailTrigger"
  );
  const isAssetEmailTriggerEnabled = assetEmailTriggerConfig
    ? assetEmailTriggerConfig.ConfigValue === "true"
    : false;

  const getbuttonData = () => {
    axiosInstance
      .get(
        `${LocalApiBaseUrl}AssetInventory/GetStandardAssets?offering=${encodeURIComponent(
          props.mainOffering
        )}&OMIDs=${encodeURIComponent(props.omids)}`
      )
      .then((response) => {
        setStandardAssets(response.data);
      })
      .catch((error) => {
        console.error("Error fetching standard assets:", error);
      });
  };

  useEffect(() => {
    if (props.mainOffering) {
      getbuttonData();
    }
  }, [props.mainOffering]);

  const getFilteredStandardAssetOptions = (assets, standardAssets) => {
    const existingAssetNames = assets.map((asset) => asset.assetName);
    const filteredStandardAssets = standardAssets.filter(
      (standardAsset) => !existingAssetNames.includes(standardAsset.AssetName)
    );
    return filteredStandardAssets.map((asset) => asset.AssetName);
  };

  const standardAssetOptions = getFilteredStandardAssetOptions(assets, standardAssets);

  const isAnyAssetEditing = () => {
    return assets.some((asset) => asset.isEditing);
  };

  const handleAddAsset = () => {
    setCurrentPage(1);
    if (!hasEditPermission(props.role)) {
      return; // Early return if user doesn't have edit permission
    }

    if (isAnyAssetEditing()) {
      alert("Please save or cancel the current asset before adding a new one.");
      return;
    }

    const newAsset = {
      approvalStatus: "",
      assetType: "",
      assetName: "",
      description: "",
      owningEntity: "",
      status: "",
      synopsClassification: "",
      journeyStage: "",
      goLiveDate: null,
      sunsetDate: null,
      isEditing: true,
    };
    setAssets([newAsset, ...assets]);
  };

  const handleNavigation = () => {
    props.history.push("/asset-adoption-inventory/view-pending-approval");
  };

  const handleEdit = (assetId) => {
    setAssets(
      assets.map((asset) => (asset.id === assetId ? { ...asset, isEditing: true } : asset))
    );
  };

  const handleDelete = (assetId) => {
    const assetIdList = [assetId];
    if (window.confirm("Are you sure you want to delete this asset?")) {
      axiosInstance
        .post(`${LocalApiBaseUrl}AssetInventory/DeleteAsset`, assetIdList)
        .then((response) => {
          fetchAssets();
          alert("Asset deleted successfully.");
        })
        .catch((error) => {
          console.error("Error deleting asset:", error);
          alert("Failed to delete asset. Please try again later.");
        });
    }
  };

  const handleSave = (updatedAsset) => {
    const isEditing = "id" in updatedAsset;

    // Find the role with edit permission
    const editRole = getUniqueRoles(props.role).find((role) => role.CanEdit);

    // Set approval status based on asset type
    const approvalStatus = updatedAsset.assetType === "Standard" ? "N/A" : "Awaiting Approval";

    // Adjust the dates to prevent the one-day shift
    const adjustDate = (date) => {
      if (!date) return null;
      const adjustedDate = new Date(date);
      adjustedDate.setHours(12); // Set to noon to avoid timezone issues
      return adjustedDate;
    };

    const payload = {
      assetId: isEditing ? updatedAsset.id : 0,
      clientName: props.client,
      contractNumber: props.contract,
      assetType: updatedAsset.assetType,
      assetName: updatedAsset.assetName.trim(),
      assetDescription: updatedAsset.description.trim(),
      assetOwningEntity: updatedAsset.owningEntity,
      assetStatus: updatedAsset.status,
      synopsClassification: updatedAsset.synopsClassification,
      JourneyStage: updatedAsset.journeyStage,
      goLiveDate: adjustDate(updatedAsset.goLiveDate),
      sunsetDate: adjustDate(updatedAsset.sunsetDate),
      creatorRole: editRole.RoleName,
      approvalStatus: approvalStatus, // NA for standard, awaiting approval for custom
      approvedOn: isEditing ? updatedAsset.approvedOn : null, // for edit, keep existing value
      OMID: props.omids.join(","),
    };
    axiosInstance
      .post(`${LocalApiBaseUrl}AssetInventory/${isEditing ? "EditAsset" : "AddAsset"}`, payload)
      .then((response) => {
        setCurrentPage(1);
        fetchAssets();
        alert(response.data.StatusMessage);
      })
      .catch((error) => {});
  };

  const handleCancel = (assetId) => {
    if (assets.find((asset) => asset.id === assetId).assetName === "") {
      // If it's a new asset being cancelled, remove it
      setAssets(assets.filter((asset) => asset.id !== assetId));
    } else {
      // If it's an existing asset, just cancel editing
      setAssets(
        assets.map((asset) => (asset.id === assetId ? { ...asset, isEditing: false } : asset))
      );
    }
  };

  return (
    <div className="asset-management-container">
      <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active={loading} />
      {!props.hasAccess ? (
        <div className="pulse-dashboard push-pulse-filter">
          <div className="select-filter-msg">
            <p className="mb-0">You don't have access to view this page</p>
          </div>
        </div>
      ) : !props.omids || props.omids.length === 0 ? (
        <div className="pulse-dashboard push-pulse-filter">
          <div className="select-filter-msg">
            <img
              src={noFilter}
              alt="Select Client name, Contract, Offering and Sub-offering to view all Assets"
            />
            <p className="mb-0">
              Select Client name, Contract, Offering and Sub-offering to view all Assets
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="header-section">
            <h3>Asset Adoption Inventory</h3>
            <div className="header-buttons">
              {hasEditPermission(props.role) && (
                <Button
                  variant="primary"
                  onClick={handleAddAsset}
                  disabled={isAnyAssetEditing() || !hasEditPermission(props.role)}
                >
                  Add Asset
                </Button>
              )}
              {isAssetEmailTriggerEnabled && (
                <Button variant="primary" onClick={handleNavigation} disabled={isAnyAssetEditing()}>
                  View My Pending Approvals
                </Button>
              )}
            </div>
          </div>

          <div
            className={`asset-table-container ${
              hasEditPermission(props.role) ? "" : "asset-table-container-viewonly"
            }`}
          >
            <div className="asset-table-header">
              <div className="asset-table-cell">Asset Type</div>
              <div className="asset-table-cell">Asset Name</div>
              <div className="asset-table-cell">
                <span data-tip data-for="assetStatusTooltip">
                  Asset Status
                </span>
                <ReactTooltip id="assetStatusTooltip" place="bottom" effect="solid">
                  <div>
                    i. <b>To Be Deployed</b> - Asset will be deployed in future for this client /
                    offering / contract <br />
                    ii. <b>Deployed</b> - Asset has already been deployed and is in use for this
                    client / offering / contract <br />
                    iii. <b>Sunset</b> - Asset was deployed but has since been decommissioned for
                    this client / offering / contract <br />
                    iv. <b>Dropped</b> - Asset was solutioned for this client / offering / contract
                    but will not be deployed
                  </div>
                </ReactTooltip>
              </div>
              <div className="asset-table-cell">Go-live Date</div>
              <div className="asset-table-cell">Sunset Date</div>
              <div className="asset-table-cell">
                <span data-tip data-for="synopsClassificationTooltip">
                  Synops Classification
                </span>
                <ReactTooltip id="synopsClassificationTooltip" place="bottom" effect="solid">
                  <div>
                    i. <b>SynOps Frontdoor</b> - Only SynOps Frontdoor Asset is classified as SynOps
                    Frontdoor <br />
                    ii. <b>Human + Machine</b> - RPA & automation tools, AI, Bots, apps that augment
                    and compliment an organizations functional/industry expertise.
                    <br />
                    iii. <b>Work Orchestration</b> - Applications that support core business process
                    execution, workflow, etc. May assign transactional tasks to robots, leverage AI,
                    provide data-based insights to assist people performing a task. <br />
                    iv. <b>Diverse Data</b> - Tools that enable collection, monitoring, storage,
                    analysis, and reporting of structured and unstructured data from diverse
                    internal and external sources. i.e. Data driven discovery & tools, Process
                    Analytics <br />
                    v. <b>Insights + Intelligence</b> - Applications that integrate advanced
                    analytics and artificial intelligence advisors to deliver actionable and
                    decision accelerating insights. i.e. Predictive Analytics, Descriptive analytics
                    & advisors <br />
                    vi. <b>SynOps Experience</b> - The optimal combination of human + machine
                    talent, driven by data and insights, to create an intelligent operating model,
                    which deliver targeted business outcomes.
                  </div>
                </ReactTooltip>
              </div>
              <div className="asset-table-cell">Journey Stage</div>
              <div className="asset-table-cell">Asset Owning Entity (Tech Org)</div>
              <div className="asset-table-cell">Asset Description</div>
              <div className="asset-table-cell">Approval Status</div>
              {hasEditPermission(props.role) && (
                <div className="asset-table-cell asset-table-actions-cell">Actions</div>
              )}
            </div>
            <div className="asset-table-body">
              {currentAssets.length === 0 ? (
                <div
                  style={{
                    marginTop: "32px",
                    marginLeft: "590px",
                    textAlign: "center",
                  }}
                >
                  No Records Found
                </div>
              ) : (
                currentAssets.map((asset) => (
                  <AssetRow
                    key={asset.id}
                    asset={asset}
                    allAssets={assets}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onSave={handleSave}
                    onCancel={handleCancel}
                    assetTypeOptions={assetTypeOptions}
                    statusOptions={statusOptions}
                    owningEntityOptions={owningEntityOptions}
                    standardAssetOptions={standardAssetOptions}
                    synopsClassificationOptions={synopsClassificationOptions}
                    journeyStageOptions={journeyStageOptions}
                    standardAssetsData={standardAssets}
                    isAnyAssetEditing={isAnyAssetEditing}
                    canEdit={hasEditPermission(props.role)}
                    getAssetApproverAPI={getAssetApproverAPI}
                  />
                ))
              )}
            </div>
          </div>

          {assets.length > 0 && (
            <AssetTablePagination
              currentPage={currentPage}
              totalPages={Math.ceil(assets.length / rowsPerPage)}
              totalRows={assets.length}
              rowsPerPage={rowsPerPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </>
      )}
    </div>
  );
};

const AssetRow = ({
  asset,
  allAssets,
  onEdit,
  onDelete,
  onSave,
  onCancel,
  assetTypeOptions,
  statusOptions,
  owningEntityOptions,
  standardAssetOptions,
  standardAssetsData,
  synopsClassificationOptions,
  journeyStageOptions,
  isAnyAssetEditing,
  canEdit,
  getAssetApproverAPI,
}) => {
  const [formData, setFormData] = useState(asset);
  const [fieldErrors, setFieldErrors] = useState({});

  const validateInput = (value) => {
    return regexForAssetInputs.test(value) || value === "";
  };

  const checkDuplicateAssetName = (assetName) => {
    const isDuplicate = allAssets.some(
      (existingAsset) =>
        existingAsset.assetName.toLowerCase().replace(/\s+/g, "") ===
          assetName.toLowerCase().replace(/\s+/g, "") && existingAsset.id !== asset.id // Exclude current asset when editing
    );
    return isDuplicate;
  };

  const isFormValid = () => {
    // Add duplicate check to form validation
    if (formData.assetType === "Custom" && checkDuplicateAssetName(formData.assetName)) {
      return false;
    }

    // Remove any undefined or empty error messages
    const actualErrors = Object.values(fieldErrors).filter((error) => error);
    // If there are any validation errors, form is invalid
    if (actualErrors.length > 0) {
      return false;
    }

    if (formData.assetType === "Standard") {
      // For Standard type, only asset type and name are mandatory
      return (
        formData.assetType !== "" &&
        formData.assetName !== "" &&
        formData.status !== "" &&
        formData.journeyStage !== "" &&
        formData.journeyStage !== undefined
      );
    } else if (formData.assetType === "Custom") {
      // For Custom type, all fields except dates are mandatory
      return (
        formData.assetType !== "" &&
        formData.assetName !== "" &&
        formData.description !== "" &&
        formData.owningEntity !== "" &&
        formData.status !== "" &&
        formData.synopsClassification !== "" &&
        formData.journeyStage !== "" &&
        formData.journeyStage !== undefined
      );
    }
    // If asset type is not selected yet
    return false;
  };

  const handleChange = async (field, value) => {
    if (field === "assetType" && value === "Custom") {
      const result = await getAssetApproverAPI();
      if (!result) {
        alert(
          "This client, contract combination doesn't have SDL/OATL assigned as approvers, please reach out to synops-asset-reporting@accenture.com to update the same, then custom asset can be added after 48 hrs"
        );
        return;
      }
    }

    if (field === "goLiveDate") {
      // reset sunset date when go live date changes
      setFormData({ ...formData, goLiveDate: value, sunsetDate: null });
    } else if (field === "assetType") {
      // Reset fields based on asset type
      const resetData = {
        ...formData,
        assetType: value,
        approvalStatus: value === "Standard" ? "N/A" : "",
        assetName: "",
        description: "",
        owningEntity: value === "Standard" ? "Accenture" : "",
        status: "",
        synopsClassification: "",
        journeyStage: "Not Applicable",
        goLiveDate: null,
        sunsetDate: null,
      };
      setFormData(resetData);
      setFieldErrors({});
    } else if (field === "assetName" && formData.assetType === "Standard") {
      // Find the matching standard asset
      const selectedAsset = standardAssetsData.find((asset) => asset.AssetName === value);

      if (selectedAsset) {
        // Populate fields with standard asset data
        setFormData({
          ...formData,
          approvalStatus: "N/A",
          assetName: selectedAsset.AssetName,
          description: selectedAsset.AssetDescription ? selectedAsset.AssetDescription : "",
          owningEntity: selectedAsset.AssetOwningEntity,
          status: selectedAsset.AssetStatus,
          synopsClassification: selectedAsset.SynopsClassification
            ? selectedAsset.SynopsClassification
            : "",
          journeyStage: selectedAsset.JourneyStage ? selectedAsset.JourneyStage : "Not Applicable",
        });
        setFieldErrors({});
      }
    } else if (field === "assetName" && formData.assetType === "Custom") {
      // Check for duplicate name for Custom assets
      const isDuplicate = checkDuplicateAssetName(value);
      const isStandardAsset = standardAssetsData.some(
        (standardAsset) =>
          standardAsset.AssetName.toLowerCase().replace(/\s+/g, "") ===
          value.trim().toLowerCase().replace(/\s+/g, "")
      );

      if (isDuplicate) {
        setFieldErrors((prev) => ({
          ...prev,
          assetName: "Asset name already exists. Please choose a different name.",
        }));
      } else if (isStandardAsset) {
        setFieldErrors((prev) => ({
          ...prev,
          assetName: "This is a standard asset, please add it under Standard category.",
        }));
      } else if (!validateInput(value)) {
        setFieldErrors((prev) => ({
          ...prev,
          assetName: "Special characters are not allowed",
        }));
      } else {
        setFieldErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors.assetName;
          return newErrors;
        });
      }
      setFormData({ ...formData, assetName: value });
    } else {
      setFormData({ ...formData, [field]: value });

      // Validate input for text fields that require validation
      if (typeof value === "string" && (field === "assetName" || field === "description")) {
        if (value !== "" && !validateInput(value)) {
          setFieldErrors((prev) => ({
            ...prev,
            [field]: "Special characters are not allowed",
          }));
        } else {
          // Remove the error if the input becomes valid or empty
          setFieldErrors((prev) => {
            const newErrors = { ...prev };
            delete newErrors[field];
            return newErrors;
          });
        }
      }
    }
  };

  const getMinSunsetDate = (goLiveDate) => {
    if (!goLiveDate) return null;
    const minDate = new Date(goLiveDate);
    minDate.setDate(minDate.getDate() + 1); // Add one day to go-live date
    return minDate;
  };

  if (!asset.isEditing) {
    return (
      <div className="asset-table-row">
        <div className="asset-table-cell">{asset.assetType}</div>
        <div className="asset-table-cell">
          {asset.assetName.length > 32 ? (
            <>
              <div
                className="asset-table-truncate-text"
                data-tip
                data-for={`asset-name-${asset.id}`}
              >
                {asset.assetName}
              </div>
              <ReactTooltip
                id={`asset-name-${asset.id}`}
                place="bottom"
                effect="solid"
                className="asset-tooltip"
              >
                {asset.assetName}
              </ReactTooltip>
            </>
          ) : (
            <div className="asset-table-truncate-text">{asset.assetName}</div>
          )}
        </div>
        <div className="asset-table-cell">{asset.status}</div>
        <div className="asset-table-cell">
          {asset.goLiveDate ? asset.goLiveDate.toLocaleDateString() : ""}
        </div>
        <div className="asset-table-cell">
          {asset.sunsetDate ? asset.sunsetDate.toLocaleDateString() : ""}
        </div>
        <div className="asset-table-cell">{asset.synopsClassification}</div>
        <div className="asset-table-cell">{asset.journeyStage}</div>
        <div className="asset-table-cell">{asset.owningEntity}</div>
        <div className="asset-table-cell">
          {asset.description.length > 41 ? (
            <>
              <div
                className="asset-table-truncate-text"
                data-tip
                data-for={`asset-desc-${asset.id}`}
              >
                {asset.description}
              </div>
              <ReactTooltip
                id={`asset-desc-${asset.id}`}
                place="bottom"
                effect="solid"
                className="asset-tooltip"
              >
                {asset.description}
              </ReactTooltip>
            </>
          ) : (
            <div className="asset-table-truncate-text">{asset.description}</div>
          )}
        </div>
        <div className="asset-table-cell">
          {asset.assetType === "Standard" || !asset.approvalStatus ? "N/A" : asset.approvalStatus}
        </div>
        {!asset.isEditing && canEdit && (
          <div className="asset-table-cell asset-table-actions-cell asset-action-buttons">
            <Button
              variant="primary"
              size="sm"
              onClick={() => onEdit(asset.id)}
              disabled={isAnyAssetEditing() || asset.approvalStatus === "Awaiting Approval"}
              className="asset-action-button"
            >
              <i className="fas fa-pencil-alt edit-icon-fix"></i>
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => onDelete(asset.id)}
              disabled={isAnyAssetEditing() || asset.approvalStatus === "Awaiting Approval"}
              className="asset-action-button"
            >
              <img src={DeleteIcon} />
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="asset-table-row">
      <div className="asset-table-cell">
        <SearchableDropdown
          options={assetTypeOptions}
          value={formData.assetType}
          onChange={(value) => handleChange("assetType", value)}
          placeholder="Select Type *"
          enableSearch={false}
        />
      </div>
      <div className="asset-table-cell">
        {formData.assetType === "Standard" ? (
          <SearchableDropdown
            options={standardAssetOptions}
            value={formData.assetName}
            onChange={(value) => handleChange("assetName", value)}
            placeholder="Select Asset *"
          />
        ) : (
          <div className="input-container">
            <Form.Control
              type="text"
              value={formData.assetName}
              onChange={(e) => handleChange("assetName", e.target.value)}
              placeholder="Enter Asset Name *"
              maxLength={150}
              isInvalid={!!fieldErrors.assetName}
            />
            {fieldErrors.assetName && (
              <Form.Control.Feedback type="invalid">{fieldErrors.assetName}</Form.Control.Feedback>
            )}
          </div>
        )}
      </div>
      <div className="asset-table-cell">
        <SearchableDropdown
          options={statusOptions}
          value={formData.status}
          onChange={(value) => handleChange("status", value)}
          placeholder="Select Status *"
          enableSearch={false}
        />
      </div>
      <div className="asset-table-cell asset-table-date-cell">
        <DatePicker
          selected={formData.goLiveDate}
          onChange={(date) => handleChange("goLiveDate", date)}
          dateFormat="MM/dd/yyyy"
          className="form-control"
          placeholderText="Select Go-Live Date"
          isClearable
        />
      </div>
      <div className="asset-table-cell asset-table-date-cell">
        <DatePicker
          selected={formData.sunsetDate}
          onChange={(date) => handleChange("sunsetDate", date)}
          dateFormat="MM/dd/yyyy"
          className="form-control"
          placeholderText="Select Sunset Date"
          minDate={getMinSunsetDate(formData.goLiveDate)}
          isClearable
        />
      </div>
      <div className="asset-table-cell">
        {formData.assetType === "Standard" ? (
          <Form.Control type="text" value={formData.synopsClassification} readOnly />
        ) : (
          <SearchableDropdown
            options={synopsClassificationOptions}
            value={formData.synopsClassification}
            onChange={(value) => handleChange("synopsClassification", value)}
            placeholder="Select Classification *"
            enableSearch={false}
          />
        )}
      </div>
      <div className="asset-table-cell">
        <SearchableDropdown
          options={journeyStageOptions}
          value={formData.journeyStage}
          onChange={(value) => handleChange("journeyStage", value)}
          placeholder="Select Journey Stage *"
          enableSearch={false}
        />
      </div>
      <div className="asset-table-cell">
        {formData.assetType === "Standard" ? (
          <Form.Control type="text" value="Accenture" disabled />
        ) : (
          <SearchableDropdown
            options={owningEntityOptions}
            value={formData.owningEntity}
            onChange={(value) => handleChange("owningEntity", value)}
            placeholder="Select Entity *"
            enableSearch={false}
          />
        )}
      </div>
      <div className="asset-table-cell">
        <div className="input-container">
          <Form.Control
            type="text"
            value={formData.description}
            onChange={(e) => handleChange("description", e.target.value)}
            readOnly={formData.assetType === "Standard"}
            placeholder={`Enter Description ${formData.assetType === "Custom" ? "*" : ""}`}
            maxLength={250}
            isInvalid={!!fieldErrors.description}
          />
          {fieldErrors.description && (
            <Form.Control.Feedback type="invalid">{fieldErrors.description}</Form.Control.Feedback>
          )}
        </div>
      </div>
      <div className="asset-table-cell">
        {formData.assetType === "Standard" || !formData.approvalStatus
          ? "N/A"
          : formData.approvalStatus}
      </div>
      {canEdit && (
        <div className="asset-table-cell asset-table-actions-cell">
          <div className="asset-action-buttons">
            <Button
              variant="success"
              size="sm"
              onClick={() => onSave(formData)}
              disabled={!isFormValid()}
              className="asset-action-button"
            >
              <img src={SaveIcon} />
            </Button>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                onCancel(formData.id);
                setFormData(asset);
              }}
              className="asset-action-button"
            >
              <img src={CancelIcon} />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    hasAccess: state.assetInventoryReducer.assetInventoryAccess,
    mainOffering: state.assetInventoryReducer.assetInventoryMainOffering,
    omids: state.assetInventoryReducer.assetInventoryOmids,
    client: state.assetInventoryReducer.assetInventoryClient,
    contract: state.assetInventoryReducer.assetInventoryContract,
    role: state.assetInventoryReducer.assetInventoryRole,
  };
};

export default withRouter(connect(mapStateToProps)(AssetAdoptionContent));
