import React, { useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { withRouter } from "react-router-dom";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import SynopsLoader from "../Shared/SynopsLoader";
import axiosInstance from "../Shared/interceptor";
import { LocalApiBaseUrl } from "../Shared/Constant";
import noFilter from "../Images/funnel-icon.svg";

const AssetPendingApprovals = (props) => {
  const gridRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [gridOptions] = useState({
    paginationPageSize: 50,
    rowHeight: 40,
    headerHeight: 40,
    animateRows: true,
    rowSelection: "multiple",
    defaultColDef: {
      resizable: true,
      sortable: true,
      tooltipValueGetter: (p) => (p.value && p.value.length >= 20 ? p.value : null),
    },
    tooltipShowDelay: 0,
    overlayNoRowsTemplate: '<span>No Records Found</span>',
  });

  const [rowData, setRowData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [popupAction, setPopupAction] = useState("");
  const [columnDefs] = useState([
    { headerCheckboxSelection: true, checkboxSelection: true, width: 41 },
    { headerName: "Asset Name", field: "assetName", filter: "agTextColumnFilter",width: 150,},
    {
      headerName: "Asset Status",
      field: "assetStatus",
      filter: "agTextColumnFilter",
      width: 120,
    },
    {
      headerName: "Go-Live Date",
      field: "goLiveDate",
      filter: "agDateColumnFilter",
      width: 120,
      valueFormatter: (p) => (p.value ? moment(p.value).format("DD MMM YYYY") : ""),
    },
    {
      headerName: "Sunset Date",
      field: "sunsetDate",
      filter: "agDateColumnFilter",
      width: 120,
      valueFormatter: (p) => (p.value ? moment(p.value).format("DD MMM YYYY") : ""),
    },
    {
      headerName: "SynOps Classification",
      field: "synOpsClassification",
      filter: "agTextColumnFilter",
      width: 200,
    },
    {
      headerName: "Asset Owning Entity (Tech Org)",
      field: "assetOwningEntity",
      filter: "agTextColumnFilter",
      width: 210,
    },
    { headerName: "Asset Description", field: "assetDescription", filter: "agTextColumnFilter" , width: 270,},
  ]);
  const [loading, setLoading] = useState(false);

  const getAssets = () => {
    setLoading(true);
    axiosInstance
      .get(`${LocalApiBaseUrl}AssetInventory/GetAssetForApproval`, {
        params: {
          OMIDs: props.omids.join(","),
        }
      })
      .then((res) => {
        setRowData(
          res.data.filter((item) => item.IsActive).map((item) => ({
            id: item.AssetId,
            assetName: item.AssetName,
            assetDescription: item.AssetDescription,
            assetOwningEntity: item.AssetOwningEntity,
            assetStatus: item.AssetStatus,
            synOpsClassification: item.SynopsClassification,
            goLiveDate: item.GoLiveDate,
            sunsetDate: item.SunsetDate,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        alert("Failed to fetch data. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleResize = () => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit();
    }
  };

  useEffect(() => {
    if(props.omids && props.omids.length > 0) {
      getAssets();
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.omids]);

  const getSelectedRows = () => {
    const selectedNodes =
      gridRef.current && gridRef.current.api ? gridRef.current.api.getSelectedNodes() : [];
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedRows(selectedData);
  };
  const handleApprove = () => {
    setPopupAction("approve");
    setShowModal(true);
  };

  const handleReject = () => {
    setPopupAction("reject");
    setShowModal(true);
  };

  const handlePopupConfirm = () => {
    let isApproved;
    const selectedIds = selectedRows.map((row) => row.id);
    if (popupAction === "approve") {
      isApproved = true;
    } else if (popupAction === "reject") {
      isApproved = false;
    }
    setLoading(true);
    axiosInstance
      .post(
        `${LocalApiBaseUrl}AssetInventory/ApproveRejectAssets?isApproved=${isApproved}`,
        selectedIds
      )
      .then(() => {
        getAssets();
        if (isApproved) {
          alert("Asset(s) have been approved.");
        } else {
          alert("Asset(s) have been rejected.");
        }
      })
      .catch((error) => {
        console.error("Error approving/rejecting assets:", error);
        alert("Failed to approve/reject assets. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
        setSelectedRows([]);
        setShowModal(false);
      });
  };

  const handlePopupClose = () => {
    gridRef.current.api.deselectAll();
    setSelectedRows([]);
    setShowModal(false);
  };

  if (!props.omids || props.omids.length === 0) {
    return (
      <div className="pulse-dashboard push-pulse-filter">
        <div className="select-filter-msg">
          <img
            src={noFilter}
            alt="Select Client name, Contract, Offering and Sub-offering to view all Assets"
          />
          <p className="mb-0">
          Select Client name, Contract, Offering and Sub-offering to view all Assets
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="asset-management-container" style={{ paddingBottom: "0" }}>
      <div className="header-section">
        <h3>
          <div
            className="fal fa-arrow-circle-left cursor-pointer"
            onClick={() => {
              props.history.push("/asset-adoption-inventory");
            }}
          ></div>{" "}
          Pending Approvals
        </h3>
        <div className="header-buttons">
          <button
            className="btn btn-primary"
            onClick={handleApprove}
            disabled={selectedRows.length === 0}
          >
            Approve
          </button>
          <button
            className="btn btn-primary"
            onClick={handleReject}
            disabled={selectedRows.length === 0}
          >
            Reject
          </button>
        </div>
      </div>
      <div className="pending-approvals-container">
        <div className="ag-theme-balham" style={{ height: "370px", width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            gridOptions={gridOptions}
            columnDefs={columnDefs}
            rowData={rowData}
            onSelectionChanged={getSelectedRows}
            pagination={true}
            paginationPageSize={50}
            rowSelection="multiple"
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
              }}
          />
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mdl-body">
        Are you sure you want to {popupAction} the selected asset(s)?
        </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-container">
          <Button variant="primary" className="pri-btn" onClick={() => handlePopupConfirm()}>
            Yes
          </Button>
          <Button variant="secondary" className="sec-btn" onClick={() => handlePopupClose()}>
            No
          </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active={loading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  omids: state.assetInventoryReducer.assetInventoryOmids,
});

export default withRouter(connect(mapStateToProps)(AssetPendingApprovals));
