import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";

const SearchableDropdown = ({ options, value, onChange, placeholder, enableSearch = true }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  // Sort options alphabetically
  const sortedOptions = enableSearch ? [...options].sort((a, b) => a.localeCompare(b)) : options;

  // Filter options based on search term only if search is enabled
  const filteredOptions = enableSearch
    ? sortedOptions.filter((option) => option.toLowerCase().includes(searchTerm.toLowerCase()))
    : sortedOptions;

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setHighlightedIndex(-1);
      }
    };

    // Handle focus change
    const handleFocusChange = (event) => {
      if (!dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setHighlightedIndex(-1);
        setSearchTerm("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("focusin", handleFocusChange);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("focusin", handleFocusChange);
    };
  }, []);

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        if (!isOpen) {
          setIsOpen(true);
          setHighlightedIndex(0);
        } else {
          setHighlightedIndex((prev) => (prev < filteredOptions.length - 1 ? prev + 1 : prev));
        }
        break;

      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex((prev) => (prev > 0 ? prev - 1 : prev));
        break;

      case "Enter":
        e.preventDefault();
        if (isOpen && highlightedIndex >= 0 && filteredOptions[highlightedIndex]) {
          onChange(filteredOptions[highlightedIndex]);
          setIsOpen(false);
          setSearchTerm("");
          setHighlightedIndex(-1);
        } else if (!isOpen) {
          setIsOpen(true);
        }
        break;

      case "Escape":
        setIsOpen(false);
        setHighlightedIndex(-1);
        setSearchTerm("");
        break;

      case "Tab":
        if (isOpen) {
          setIsOpen(false);
          setHighlightedIndex(-1);
          setSearchTerm("");
        }
        break;

      default:
        break;
    }
  };

  const handleDropdownClick = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    onChange(option);
    setIsOpen(false);
    setSearchTerm("");
    setHighlightedIndex(-1);
  };

  return (
    <div className="custom-dropdown-asset" ref={dropdownRef}>
      <div
        className="dropdown-header"
        onClick={handleDropdownClick}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        role="combobox"
        aria-expanded={isOpen}
        aria-haspopup="listbox"
      >
        {value || placeholder}
      </div>

      {isOpen && (
        <div className="dropdown-content">
          {enableSearch && (
            <div className="search-container" style={{ padding: "8px" }}>
              <Form.Control
                ref={searchInputRef}
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setHighlightedIndex(0);
                }}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={handleKeyDown}
              />
            </div>
          )}

          <div className="options-container" role="listbox">
            {filteredOptions.map((option, index) => (
              <div
                key={option}
                className={`dropdown-option ${index === highlightedIndex ? "highlighted" : ""}`}
                onClick={() => handleOptionSelect(option)}
                role="option"
                aria-selected={value === option}
                style={{
                  padding: "4px 8px",
                  cursor: "pointer",
                  backgroundColor:
                    index === highlightedIndex ? "#f8f9fa" : value === option ? "#e9ecef" : "#fff",
                }}
              >
                {option}
              </div>
            ))}
            {filteredOptions.length === 0 && (
              <div style={{ padding: "4px 8px", color: "#6c757d" }}>No matches found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
