export const SET_ASSET_INVENTORY_ACCESS = 'SET_ASSET_INVENTORY_ACCESS';
export const SET_ASSET_INVENTORY_CLIENT = 'SET_ASSET_INVENTORY_CLIENT';
export const SET_ASSET_INVENTORY_CONTRACT = 'SET_ASSET_INVENTORY_CONTRACT';
export const SET_ASSET_INVENTORY_OMIDS = 'SET_ASSET_INVENTORY_OMIDS';
export const SET_ASSET_INVENTORY_ROLE = 'SET_ASSET_INVENTORY_ROLE';
export const SET_ASSET_INVENTORY_OFFERING_DATA = 'SET_ASSET_INVENTORY_OFFERING_DATA';
export const SET_ASSET_INVENTORY_ALL_OFFERING = 'SET_ASSET_INVENTORY_ALL_OFFERING';
export const SET_ASSET_INVENTORY_ALL_SUB_OFFERING = 'SET_ASSET_INVENTORY_ALL_SUB_OFFERING';
export const SET_ASSET_INVENTORY_MAIN_OFFERING = 'SET_ASSET_INVENTORY_MAIN_OFFERING';
export const SET_ASSET_INVENTORY_OFFERING_SSG = 'SET_ASSET_INVENTORY_OFFERING_SSG';
export const SET_ASSET_INVENTORY_SUB_OFFERING = 'SET_ASSET_INVENTORY_SUB_OFFERING';
export const SET_ASSET_INVENTORY_PROCESS = 'SET_ASSET_INVENTORY_PROCESS';

export function setAssetInventoryClient(dataObj) {
  return {
    type: SET_ASSET_INVENTORY_CLIENT,
    payload: dataObj,
  };
}

export function setAssetInventoryContract(data){
  return{
    type : SET_ASSET_INVENTORY_CONTRACT,
    payload: data,
  }
}

export function setAssetInventoryOmids(data){
  return{
    type : SET_ASSET_INVENTORY_OMIDS,
    payload: data,
  }
}

export function setAssetInventoryRole(data){
  return{
    type : SET_ASSET_INVENTORY_ROLE,
    payload: data,
  }
}

export function setAssetInventoryOffering(data){
  return{
    type : SET_ASSET_INVENTORY_OFFERING,
    payload: data,
  }
}